main {
	min-height: calc(100vh - 170px);
}

.footer {
	padding: 10px 0;
	height: 200px;

	.arrowIcon {
		display: inline-block;
		transition: transform 0.7s;
	}

	.arrowIcon:hover {
		animation: moveRight 0.7s forwards, oscillate 0.7s 0.7s infinite;
	}

	.arrowIcon:not(:hover) {
		animation: moveBack 0.7s forwards;
	}

	@keyframes moveRight {
		0% {
			transform: translateX(0);
		}
		100% {
			transform: translateX(20px);
		}
	}

	@keyframes oscillate {
		0% {
			transform: translateX(20px);
		}
		30% {
			transform: translateX(23px);
		}
		50% {
			transform: translateX(20px);
		}
		70% {
			transform: translateX(15px);
		}
		100% {
			transform: translateX(20px);
		}
	}

	@keyframes moveBack {
		0% {
			transform: translateX(20px);
		}
		100% {
			transform: translateX(0);
		}
	}
}

.carousel-control-next {
	width: 85%;
	padding-right: 5px;
	justify-content: end;
}

.carousel-control-prev {
	width: 15%;
	padding-left: 5px;
	justify-content: start;
}

.nav-item .nav-link {
	color: #efb73e !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin-bottom: 0;
}

.d-flex-start {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.d-flex-center {
	display: flex;
	justify-content: center;
	align-items: center;
}

@media screen and (max-width: 992px) {
	.footer {
		height: auto;
	}
}

@media screen and (max-width: 576px) {
	.footer {
		height: auto;

		.getInTouch {
			font-size: 30px;
		}

		.mailAndPhone {
			font-size: 14px;
		}
	}

	.fs-24 {
		font-size: 24px !important;
	}
	.fs-22 {
		font-size: 22px !important;
	}
	.fs-20 {
		font-size: 20px !important;
	}
	.fs-18 {
		font-size: 18px !important;
	}
	.fs-16 {
		font-size: 16px !important;
	}
	.fs-14 {
		font-size: 14px !important;
	}
	.fs-12 {
		font-size: 12px !important;
	}
	.fs-10 {
		font-size: 10px !important;
	}
	.fs-8 {
		font-size: 8px !important;
	}
}
